<template>
  <div v-if="showComponent" class="product-description">
    <div class="product-description__title">{{ title }}</div>
    <div class="product-description__description">
      <custom-block-content :blocks="descriptionContent" />
    </div>
    <div
      v-if="localAttachments.length"
      class="product-description__attachments"
    >
      <div
        v-for="attachment in localAttachments"
        :key="attachment.id"
        class="product-description__attachment"
      >
        <product-description-attachment
          :attachment="attachment"
          :tender="tender"
          :supplier-id="supplierId"
          :removable="true"
          :read-only="readOnly"
          @remove="removeAttachment(attachment)"
        />
      </div>
    </div>
    <file-input
      v-if="!readOnly"
      v-slot="{ openFileInput }"
      :valid-file-extensions="validFileExtensions"
      @selected-files="handleSelectedFiles"
    >
      <div class="product-description__input-section">
        <secondary-button
          :icon-src="addAttachmentsButtonIcon"
          :text="addAttachmentsButtonText"
          @click="openFileInput"
        />
        <div>({{ formattedValidFileExtensions }})</div>
      </div>
    </file-input>
  </div>
</template>

<script>
import FileInput from "@/components/FileInput/FileInput.vue";
import ProductDescriptionAttachment from "@/views/TenderOffer/EditOfferView/UploadProductDescription/ProductDescriptionAttachment.vue";
import { uuidv4 } from "@/utilities/uuid";
import {
  getFilesWithValidExtension,
  getFilesWithValidSize,
} from "@/utilities/fileUtilities";
import cmsService from "@/services/cmsService";
import CustomBlockContent from "@/components/CustomBlockContent/CustomBlockContent.vue";
import SecondaryButton from "@/components/Buttons/SecondaryButton.vue";
import { cloneDeep, isEqual, sortBy } from "lodash";

export default {
  components: {
    SecondaryButton,
    CustomBlockContent,
    ProductDescriptionAttachment,
    FileInput,
  },
  props: {
    pageCms: {
      type: Object,
      required: true,
    },
    offer: {
      type: Object,
      required: true,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    tender: {
      type: Object,
      required: true,
    },
    supplierId: {
      type: Number,
      required: true,
    },
  },
  emits: ["new-offer"],
  data() {
    return {
      initialized: false,
      maxFileSizeInMB: 20,
      localAttachments: [],
      validFileExtensions: [".pdf", ".jpg", ".jpeg", ".png"],
    };
  },
  computed: {
    showComponent() {
      return !(this.readOnly && this.localAttachments.length === 0);
    },
    productDescriptionUploadCms() {
      return this.pageCms.pageSections.find(
        (section) => section.label === "uploadProductDescription",
      );
    },
    title() {
      return this.productDescriptionUploadCms.title;
    },
    descriptionContent() {
      return this.productDescriptionUploadCms.content;
    },
    addAttachmentsButton() {
      return this.productDescriptionUploadCms.buttons.find(
        (button) => button.label === "addAttachmentsButton",
      );
    },
    addAttachmentsButtonText() {
      return this.addAttachmentsButton.title;
    },
    addAttachmentsButtonIcon() {
      const icon = this.addAttachmentsButton.icons[0].icon;
      return cmsService.resolveImage(icon).url();
    },
    formattedValidFileExtensions() {
      return this.validFileExtensions.join(", ");
    },
  },
  watch: {
    "offer.attachments": {
      handler(newOfferAttachments) {
        if (
          this.checkIfAttachmentsAreEqual(
            newOfferAttachments,
            this.localAttachments,
          )
        ) {
          return;
        }
        this.localAttachments = cloneDeep(newOfferAttachments);
      },
      deep: true,
      immediate: true,
    },
    localAttachments: {
      handler() {
        if (
          this.checkIfAttachmentsAreEqual(
            this.offer.attachments,
            this.localAttachments,
          )
        ) {
          return;
        }
        const offerCopy = cloneDeep(this.offer);
        offerCopy.attachments = cloneDeep(this.localAttachments);
        this.$emit("new-offer", offerCopy);
      },
      deep: true,
    },
  },
  methods: {
    checkIfAttachmentsAreEqual(recordsA, recordsB) {
      return isEqual(
        sortBy(recordsA, (record) => record.id),
        sortBy(recordsB, (record) => record.id),
      );
    },
    handleSelectedFiles(files) {
      let validFiles = getFilesWithValidExtension(
        files,
        this.validFileExtensions,
      );
      validFiles = getFilesWithValidSize(validFiles, this.maxFileSizeInMB);
      const newAttachments = validFiles.map((file) => {
        return {
          id: uuidv4(),
          file: file,
          fileName: file.name,
          attachmentUri: null,
        };
      });
      this.localAttachments = [...newAttachments, ...this.localAttachments];
    },
    removeAttachment(attachment) {
      for (let i = this.localAttachments.length - 1; i >= 0; i--) {
        if (this.localAttachments[i].id === attachment.id) {
          this.localAttachments.splice(i, 1);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.product-description {
  &__title {
    font-weight: 600;
    font-size: $font-size-lg;
  }

  &__description {
    margin: 0;
    font-weight: 400;
    font-size: $font-size-sm;
  }

  &__input-section {
    margin-top: 1rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  &__input-button {
    border: none;
    padding: 0.625rem 1rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    border-radius: 8px;
    background-color: $color-primary-pink-dark;

    &:hover {
      background: $color-primary-pink-darker;
    }
  }

  &__attachments {
    margin-top: 1rem;
  }

  &__attachment {
    &:not(:first-child) {
      margin-top: 0.5rem;
    }
  }

  &__loader {
    padding: 2rem 0 3rem;
    display: flex;
    justify-content: center;
  }
}
</style>
@/utilities/uuid@/utilities/fileUtilities
