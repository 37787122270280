<template>
  <div class="public-notes">
    <div v-if="isLoading" class="public-notes__loader">
      <b-spinner
        class="public-notes__loader-spinner"
        type="grow"
        label="Loading..."
      />
    </div>
    <template v-else-if="publicNotes">
      <div v-if="publicNotes.length > 0" class="public-notes__list">
        <PublicNoteItem
          v-for="publicNote in publicNotes"
          :key="publicNote.id"
          :public-note="publicNote"
          :is-unread="isNoteUnread(publicNote)"
          @clicked="handlePublicNoteClick"
        />
      </div>
      <p v-else>Ingen fellesmeldinger sendt</p>
    </template>
    <div v-else class="public-notes__loader">
      <b-spinner class="public-notes__loader-spinner" type="grow" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, onMounted, watch, computed } from "vue";
import { Tender, PriceRequest, PublicNote } from "@/stores/tender/types";
import { useTenderApi } from "@/services/api/useTenderApi";
import PublicNoteItem from "./PublicNoteItem.vue";
import PublicNotePanel from "@/components/PublicNotePanel/PublicNotePanel.vue";
import { useSidePanelStore } from "@/stores/sidePanel/sidePanel";
import { useSupplierStore } from "@/stores/supplier";

const sidePanelStore = useSidePanelStore();

const props = defineProps<{
  tender: Tender;
  priceRequest?: PriceRequest;
  supplierId: number;
}>();

const { getPublicNotes } = useTenderApi();

const supplierStore = useSupplierStore();

const supplier = computed(() => supplierStore.supplier);
const publicNoteToView = ref<PublicNote | undefined>(undefined);
const publicNotes = ref<PublicNote[] | null>(null);
const isLoading = ref(false);
const readNoteIds = ref<Set<number>>(new Set());

const fetchPublicNotes = async () => {
  if (!props.priceRequest || !supplier.value?.id) return;
  isLoading.value = true;
  try {
    const response = await getPublicNotes({
      supplierId: supplier.value.id,
      priceRequestId: props.priceRequest.id,
    });
    publicNotes.value = Array.isArray(response.data) ? response.data : [];
  } catch (error) {
    console.error("Failed to fetch public notes:", error);
    publicNotes.value = [];
  } finally {
    isLoading.value = false;
  }
};

const handlePublicNoteClick = (note: PublicNote) => {
  sidePanelStore.openPanel("Fellesmelding", PublicNotePanel, {
    publicNote: note,
    tenderId: props.tender.tenderId,
    supplierId: props.supplierId,
  });
  publicNoteToView.value = note;
  markNoteAsRead(note.id);
};

const isNoteUnread = (note: PublicNote): boolean => {
  return !readNoteIds.value.has(note.id);
};

const markNoteAsRead = (noteId: number) => {
  if (!readNoteIds.value.has(noteId)) {
    readNoteIds.value.add(noteId);
    saveReadNoteIdsToLocalStorage();
  }
};

const saveReadNoteIdsToLocalStorage = () => {
  localStorage.setItem(
    getLocalStorageKey(),
    JSON.stringify(Array.from(readNoteIds.value)),
  );
};

const loadReadNoteIdsFromLocalStorage = () => {
  const storedIds = localStorage.getItem(getLocalStorageKey());
  if (storedIds) {
    readNoteIds.value = new Set(JSON.parse(storedIds));
  }
};

const getLocalStorageKey = () => {
  return `readNoteIds_${props.tender.tenderId}_${props.priceRequest?.id}`;
};

watch(() => props.priceRequest, fetchPublicNotes);

onMounted(() => {
  loadReadNoteIdsFromLocalStorage();
  fetchPublicNotes();
});
</script>

<style lang="scss" scoped>
.public-notes {
  &__list {
    margin-bottom: 0.5rem;
  }

  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
  }

  &__loader {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100px;
  }

  &__loader-spinner {
    width: 1.5rem;
    height: 1.5rem;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
