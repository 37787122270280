<template>
  <b-modal
    v-model="showModal"
    :no-close-on-backdrop="true"
    centered
    hide-footer
    hide-header
    body-class="p-0"
  >
    <div v-if="sendOfferPageCms" class="send-offer-modal__content">
      <send-offer-form
        v-if="!offerSent"
        :tender="tender"
        :service-offer="serviceOffer"
        :supplier-id="supplierId"
        :send-offer-page-cms="sendOfferPageCms"
        @cancel="closeModal"
        @offer-sent="onOfferSent"
      />
      <offer-sent
        v-else
        :send-offer-page-cms="sendOfferPageCms"
        :customer-org-name="tender.customerOrganization.name"
        @close="closeModal"
      />
    </div>
    <div v-else class="send-offer-modal__loader">
      <b-spinner />
    </div>
  </b-modal>
</template>

<script>
import SendOfferForm from "@/components/SendOfferModal/SendOfferForm.vue";
import OfferSent from "@/components/SendOfferModal/OfferSent.vue";
import cmsService from "@/services/cmsService";

export default {
  components: { OfferSent, SendOfferForm },
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
    tender: {
      type: Object,
      required: true,
    },
    serviceOffer: {
      type: Object,
      required: true,
    },
    supplierId: {
      type: Number,
      required: true,
    },
  },
  emits: ["update:modelValue", "offer-sent"],
  data() {
    return {
      offerSent: false,
      sendOfferPageCms: null,
    };
  },
  computed: {
    showModal: {
      get() {
        return this.modelValue;
      },
      set(newVal) {
        this.$emit("update:modelValue", newVal);
      },
    },
  },
  async created() {
    this.sendOfferPageCms = await cmsService.getSendOfferModalPage();
  },
  methods: {
    closeModal() {
      this.showModal = false;
    },
    onOfferSent(data) {
      this.offerSent = true;
      this.$emit("offer-sent", data);
    },
  },
};
</script>

<style lang="scss" scoped>
.send-offer-modal {
  &__content {
    display: block;
    padding: 2.5rem;
    font-family: "Montserrat", sans-serif;
  }

  &__loader {
    padding: 8rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
