<template>
  <div class="tender-conversations">
    <template v-if="isReady">
      <conversation-thread
        :conversation="conversation"
        :tender="tender"
        :supplier-id="supplierId"
        @send-message="sendMessage"
      >
        <template #error>
          <div v-if="fetchError" class="tender-conversations__error">
            {{ fetchError }}
          </div>
        </template>
      </conversation-thread>
    </template>
    <div v-else class="tender-conversations__loader">
      <b-spinner type="grow" />
    </div>
  </div>
</template>

<script>
import ConversationThread from "@/components/Conversation/ConversationThread.vue";
import supplierCommunicationService from "@/services/supplierCommunicationService";
import {
  MessageDomain,
  MessageType,
  RequestState,
  TenderState,
} from "@/custom-types/types";
import { getMessageAttachmentDto } from "@/utilities/fileUtilities";
import tenderService from "@/services/tenderService";

export default {
  components: { ConversationThread },
  props: {
    tender: {
      type: Object,
      required: true,
    },
    priceRequest: {
      type: Object,
      required: true,
    },
    supplierId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      isReady: false,
      conversation: null,
      fetchError: null,
    };
  },
  computed: {
    user() {
      return this.$store.state.api.user;
    },
    supplierRequest() {
      return this.priceRequest.supplierRequests.find(
        (supplierRequest) => supplierRequest.supplierId === this.supplierId,
      );
    },
  },
  async created() {
    await this.fetchPriceRequestConversation();
    this.isReady = true;
  },
  methods: {
    async fetchConversations() {
      const supplierRequestId = this.supplierRequest?.id;
      if (!supplierRequestId) {
        throw new Error("Supplier request ID is undefined");
      }

      const response =
        await supplierCommunicationService.fetchSupplierConversations(
          supplierRequestId,
        );
      return response.data;
    },
    async fetchConversationMessages(conversationId) {
      const response =
        await supplierCommunicationService.fetchConversationMessages(
          conversationId,
        );
      return response.data;
    },
    async fetchPriceRequestConversation(mustHaveConversation = false) {
      try {
        if (!this.conversation) {
          let conversations = await this.fetchConversations();
          if (mustHaveConversation) {
            let attempts = 0;
            const maxAttempts = 5;

            while (!conversations.length && attempts < maxAttempts) {
              await new Promise((resolve) => setTimeout(resolve, 1000));
              conversations = await this.fetchConversations();
              attempts++;
            }

            if (attempts === maxAttempts && !conversations.length) {
              throw new Error(
                "Failed to fetch conversations after multiple attempts.",
              );
            }
          }

          const sortedConversations = conversations
            .slice()
            .sort((conversationA, conversationB) => {
              const latestDateA = new Date(conversationA.message.createdOn);
              const latestDateB = new Date(conversationB.message.createdOn);
              return latestDateA.getTime() - latestDateB.getTime();
            });
          this.conversation = sortedConversations.length
            ? sortedConversations[0]
            : null;
        }

        if (this.conversation) {
          const newConversation = await this.fetchConversationMessages(
            this.conversation.latestMessage.conversationId,
          );
          Object.assign(this.conversation, newConversation);
        }

        this.fetchError = null;
      } catch (error) {
        console.error("Error fetching price request conversation:", error);
        this.fetchError =
          error.response?.data?.message ||
          error.message ||
          "Failed to fetch conversations. Please try again later.";
      }
    },
    async sendMessage(payload) {
      try {
        const message = payload.message;
        const messageAttachments = await Promise.all(
          payload.attachments.map((file) => getMessageAttachmentDto(file)),
        );

        let response;
        if (
          !this.conversation &&
          this.tender.tenderState !== TenderState.Accepted &&
          (this.supplierRequest?.requestState === RequestState.PriceRequested ||
            RequestState.InformationRequested)
        ) {
          response = await this.sendNeedMoreInfoMessage(
            message,
            messageAttachments,
          );
        } else {
          response = await this.sendGeneralMessage(message, messageAttachments);
        }

        if (response.error) {
          this.fetchError =
            response.error || "Failed to send message. Please try again.";
          throw new Error(response.error);
        }

        await this.fetchPriceRequestConversation(true);
        payload.successCallback();
        this.fetchError = null; // Clear any previous errors
      } catch (error) {
        console.error("Error sending message:", error);
        this.fetchError =
          error.response?.data ||
          error.message ||
          "Failed to send message. Please try again.";
        payload.errorCallback(error);
      }
    },
    async sendNeedMoreInfoMessage(message, messageAttachments) {
      const messageDto = {
        customerPropertyId: this.priceRequest.customerPropertyId,
        supplierId: this.supplierId,
        message: message,
        attachments: messageAttachments,
        tenderId: this.tender.tenderId,
        userId: this.$store.state.api.user.userId,
      };
      return await tenderService.sendInformationRequestMessage(
        this.tender.tenderId,
        messageDto,
      );
    },
    async sendGeneralMessage(message, messageAttachments) {
      const messageDto = {
        conversationId: this.conversation
          ? this.conversation.latestMessage.conversationId
          : null,
        messageType: MessageType.GeneralMessage,
        messageBody: message,
        attachments: messageAttachments,
        senderUserId: this.user.userId,
        senderName: this.user.name,
        senderEmail: this.user.email,
        supplierId: this.supplierRequest ? this.supplierRequest.supplierId : "",
        supplierRequestId: this.supplierRequest ? this.supplierRequest.id : "",
        tenderId: this.tender.tenderId,
        rootMessage: !this.conversation,
        messageDomain: MessageDomain.SupplierCustomer,
        price: null,
      };
      return await supplierCommunicationService.sendMessage(messageDto);
    },
  },
};
</script>

<style lang="scss" scoped>
.tender-conversations {
  &__loader {
    padding: 4rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
@/utilities/fileUtilities @/types/AppTypes
