<template>
  <div>
    <div
      v-for="(attachment, index) in attachments"
      :key="index"
      class="attachment-list-item-container"
    >
      <div
        class="attachment-list-item"
        @click="handleAttachmentClick(attachment)"
      >
        <!-- Left icon -->
        <img
          :src="`/images/document_32.svg`"
          alt="left icon"
          class="attachment-list-item__left-icon"
        />

        <!-- Title -->
        <div class="attachment-list-item__title-container">
          <p class="attachment-list-item__title">
            {{ attachment.fileName }}
          </p>
        </div>

        <!-- Right icon -->
        <img
          :src="`/images/download.svg`"
          alt="right icon"
          class="attachment-list-item__right-icon"
        />
      </div>
    </div>
  </div>
</template>

<script>
import useFileDownload from "@/hooks/useFileDownload.ts";

const { viewAttachmentFile } = useFileDownload();

export default {
  props: {
    attachments: {
      type: Array,
      required: true,
    },
    tender: {
      type: Object,
      required: true,
    },
    supplierId: {
      type: Number,
      required: true,
    },
  },
  methods: {
    handleAttachmentClick(attachment) {
      viewAttachmentFile(
        {
          attachmentId: attachment.attachmentId,
          tenderId: this.tender.tenderId,
          supplierRequestId:
            this.tender.priceRequests?.[0].supplierRequests?.[0]?.id,
          supplierId: this.supplierId,
        },
        this.$router,
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.attachment-list-item-container {
  margin-top: 1rem;
  margin-bottom: 1rem;
  cursor: pointer;
  border-radius: 0.25rem;

  &--shadow {
    box-shadow: 0 4px 10px rgba($color-black, 0.1);
  }

  &--background-fill {
    background: $color-white;
  }

  &--background-transparent {
    background: transparent;
  }
}

.attachment-list-item {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  width: fit-content;
  color: $color-black;
  border-radius: 0.25rem;
  padding: 0.5rem;
  position: relative;
  border: 0.1rem solid gray;

  &__title-container {
    display: flex;
    flex: 1;
    width: 18rem;

    span {
      font-weight: 600;
      font-size: $font-size-base;
    }
  }

  &--active {
    cursor: pointer;

    &:hover {
      background: #f6f6f6;
    }

    &:active {
      background: #f6f6f6;
    }

    &:focus {
      border: 0.1rem solid $color-black;
      background: #f1f1f1;
    }
  }

  hr {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 1px;
    border: none;
    color: #e1e1e1;
    background-color: #e1e1e1;
    opacity: 1;
  }

  &__left-icon {
    flex-shrink: 0;
    margin-right: 0.5rem;
  }

  &__left-text-container {
    flex-grow: 1;
    padding-right: 2rem;
  }

  &__title {
    font-weight: 600;
    font-size: $font-size-base;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin-bottom: 0;
  }

  &__right-text-container {
    display: flex;
    align-items: baseline;
  }

  &__right-icon {
    flex-shrink: 0;
    margin-left: auto;
  }

  &__left-icon-dummy {
    height: 1px;
    margin-top: -1px;
    overflow-y: hidden;
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
  }

  &__collapse-content-slot {
    flex-grow: 1;
  }
}
</style>
@/router
