<template>
  <div class="attachment" @click="handleAttachmentClick">
    <img
      :src="`/images/document_24.svg`"
      alt="Document icon"
      class="attachment__icon"
    />
    <div class="attachment__file-name">
      {{ attachment.fileName }}
    </div>
  </div>
</template>

<script setup>
import useFileDownload from "@/hooks/useFileDownload";
import { useRouter } from "vue-router";

const props = defineProps({
  attachment: {
    type: Object,
    required: true,
  },
  supplierId: {
    type: Number,
    required: true,
  },
});

const router = useRouter();
const { viewAttachmentFile } = useFileDownload();

const handleAttachmentClick = async () => {
  if (props.attachment.attachmentId) {
    try {
      await viewAttachmentFile(
        {
          attachmentId: props.attachment.attachmentId,
          supplierId: props.supplierId,
        },
        router,
      );
    } catch (error) {
      console.error("Error viewing attachment:", error);
    }
  } else if (props.attachment.attachmentUri) {
    window.open(props.attachment.attachmentUri, "_blank");
  }
};
</script>

<style lang="scss" scoped>
.attachment {
  border: 1px solid gray;
  display: flex;
  align-items: center;
  padding: 0.5rem;
  border-radius: 4px;
  cursor: pointer;

  &__left-icon {
    flex-shrink: 0;
    margin-right: 0.5rem;
  }

  &__title {
    flex-grow: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__file-name {
    flex-grow: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &:hover {
    background: #f6f6f6;
  }
}
</style>
