<template>
  <div class="public-note-panel">
    <div class="public-note-panel__date-sent">
      Sendt {{ formattedCreatedOn }}
    </div>
    <div class="public-note-panel__title">
      {{ publicNote.title }}
    </div>
    <div class="public-note-panel__message">
      <MessageRenderer :message="publicNote.message" />
    </div>
    <div v-if="attachmentsList.length">
      <div class="public-note-panel__attachments-title">Vedlegg</div>
      <div class="public-note-panel__attachments-list">
        <Attachment
          v-for="attachment in attachmentsList"
          :key="attachment.id"
          :file-name="attachment.fileName"
          @attachment-clicked="attachment.viewHandler"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from "vue";
import MessageRenderer from "@/components/MessageRenderer/MessageRenderer.vue";
import Attachment from "@/components/Attachment/Attachment.vue";
import { formatDateBasedOnTimeLapsed } from "@/utilities/dateUtils";
import useFileDownload from "@/hooks/useFileDownload";
import { useFileAttachmentApi } from "@/services/api/useFileAttachmentApi";
import { useRouter } from "vue-router";
import { PublicNote } from "@/stores/tender/types";

const props = defineProps<{
  publicNote: PublicNote;
  tenderId: number;
  supplierId: number;
}>();

const { viewFileInNewWindow } = useFileDownload();
const { getPublicNoteAttachment } = useFileAttachmentApi();
const router = useRouter();

const attachmentsList = computed(() =>
  props.publicNote.attachments.map((attachment) => ({
    id: attachment.attachmentId,
    fileName: attachment.fileName,
    viewHandler: () =>
      viewFileInNewWindow(async () => {
        const response = await getPublicNoteAttachment({
          attachmentId: attachment.attachmentId,
          tenderId: props.tenderId,
          fileName: attachment.fileName,
          supplierId: props.supplierId,
        });
        return response.data;
      }, router),
  })),
);

const formattedCreatedOn = computed(() =>
  formatDateBasedOnTimeLapsed(props.publicNote.createdOn),
);
</script>

<style lang="scss" scoped>
.public-note-panel {
  min-height: 100vh;

  &__date-sent {
    margin-top: 0.875rem;
    font-size: 0.875rem;
    font-weight: 400;
  }

  &__title {
    margin-top: 0.5rem;
    font-size: 1rem;
    font-weight: 600;
  }

  &__message {
    margin-top: 0.5rem;
    font-size: 0.875rem;
    font-weight: 400;
  }

  &__attachments-title {
    margin-top: 2rem;
    font-size: 1rem;
    font-weight: 600;
  }

  &__attachments-list {
    margin-top: 0.5rem;
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    margin-bottom: 1rem;
  }

  &__buttons {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
