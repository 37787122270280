import { executeApiRequest } from "@/services/api/apiService";
import {
  AttachmentApi,
  AttachmentApiEndpoints,
} from "@/config/api/attachment/attachmentApi";

interface FileAttachmentRequest {
  attachmentId: string;
  tenderId: number;
  supplierRequestId: number;
  supplierId: number;
}

interface AttachmentRequest {
  attachmentId: string;
  tenderId: number;
  fileName: string;
  supplierId: number;
}

const fetchFileAttachment = async (req: FileAttachmentRequest) => {
  return executeApiRequest<Blob>(
    {
      endpointKey: Symbol.for(AttachmentApiEndpoints.GET_FILE_ATTACHMENT),
      config: AttachmentApi,
    },
    {
      pathParams: [req.attachmentId, "supplier", req.supplierId],
      responseType: "blob",
    },
  );
};

const getPublicNoteAttachment = async (req: AttachmentRequest) => {
  return executeApiRequest<Blob>(
    {
      endpointKey: Symbol.for(AttachmentApiEndpoints.GET_ATTACHMENT),
      config: AttachmentApi,
    },
    {
      pathParams: [req?.attachmentId || "", "supplier", req?.supplierId || ""],
      responseType: "blob",
    },
  );
};

const getAttachment = async (req: AttachmentRequest) => {
  return executeApiRequest<Blob>(
    {
      endpointKey: Symbol.for(AttachmentApiEndpoints.GET_ATTACHMENT),
      config: AttachmentApi,
    },
    {
      pathParams: [req?.attachmentId || "", "supplier", req?.supplierId || ""],
      responseType: "blob",
    },
  );
};

type UseFileAttachmentApi = () => {
  fetchFileAttachment: typeof fetchFileAttachment;
  getPublicNoteAttachment: typeof getPublicNoteAttachment;
  getAttachment: typeof getAttachment;
};

export const useFileAttachmentApi: UseFileAttachmentApi = () => {
  return {
    fetchFileAttachment,
    getPublicNoteAttachment,
    getAttachment,
  };
};
