import {
  getTendersFromStore,
  mapToAddressTenders,
  createActiveTenders,
} from "@/utilities/offerUtilities";
import { RequestState } from "@/custom-types/GeneralTypes";
import { cloneDeep } from "lodash";

// Constants
const REQUEST_STATE_GROUPS = {
  REQUESTED: [
    RequestState.PRICE_REQUESTED,
    RequestState.INFORMATION_REQUESTED,
    RequestState.LOST,
    RequestState.UNANSWERED,
    RequestState.REJECTED,
    RequestState.DRAFT,
  ],
  TERMINATED: [
    RequestState.COMPLETED,
    RequestState.REJECTED,
    RequestState.LOST,
    RequestState.UNANSWERED,
    RequestState.ARCHIVED,
  ],
};

// Checks for request state
const RequestStateChecks = {
  isArchived: (state) => state === RequestState.ARCHIVED,

  isRejectedOrLost: (state) =>
    [RequestState.REJECTED, RequestState.LOST].includes(state),

  isAccepted: (state) => state === RequestState.ACCEPTED,

  isTerminated: (state) =>
    [
      RequestState.COMPLETED,
      RequestState.REJECTED,
      RequestState.LOST,
      RequestState.ARCHIVED,
      RequestState.UNANSWERED,
    ].includes(state),

  isRequested: (state) => REQUEST_STATE_GROUPS.REQUESTED.includes(state),

  isActive: (state) => state === RequestState.ACCEPTED,

  matchesStates: (state, validStates) => validStates.includes(state),
};

const TenderStateChecks = {
  isCompletedOrAccepted: (state) =>
    [RequestState.COMPLETED, RequestState.ACCEPTED].includes(state),

  isTerminated: (state) => state === RequestState.COMPLETED,

  isAccepted: (state) => state === RequestState.ACCEPTED,

  isFinalState: (state) =>
    [RequestState.COMPLETED, RequestState.ACCEPTED].includes(state),
};

/**
 * Check if the request was rejected by supplier
 */
function isSupplierRejected(supplierRequest) {
  if (!supplierRequest.requestStateChanges?.length) return false;

  // Get the latest state change
  const latestChange = supplierRequest.requestStateChanges.reduce(
    (latest, current) => {
      return !latest || new Date(current.createdOn) > new Date(latest.createdOn)
        ? current
        : latest;
    },
    null,
  );

  return (
    (latestChange &&
      latestChange.stateTo === "Rejected" &&
      latestChange.audience === "Supplier") ||
    (latestChange &&
      latestChange.stateTo === "Rejected" &&
      latestChange.audience === "Customer")
  );
}

/**
 * Validates if a supplier request should be in requested offers
 */
function isRequestedOffer(supplierRequest, tender, supplierId) {
  if (supplierRequest.supplierId !== supplierId) {
    return false;
  }

  if (
    [RequestState.REJECTED, RequestState.LOST].includes(
      supplierRequest.requestState,
    )
  ) {
    return false;
  }

  if (
    TenderStateChecks.isFinalState(supplierRequest.requestState) ||
    RequestStateChecks.isActive(supplierRequest.requestState) ||
    supplierRequest.archived ||
    isSupplierRejected(supplierRequest)
  ) {
    return false;
  }

  return RequestStateChecks.isRequested(supplierRequest.requestState);
}

/**
 * Validates if a supplier request should be in terminated offers
 */
function isTerminatedRequest(supplierRequest, tender, supplierId) {
  if (supplierRequest.supplierId !== supplierId) {
    return false;
  }

  if (
    [RequestState.REJECTED, RequestState.LOST].includes(
      supplierRequest.requestState,
    )
  ) {
    return true;
  }

  if (supplierRequest.requestState === RequestState.UNANSWERED) {
    return true;
  }

  const isActive = supplierRequest.requestState === RequestState.ACCEPTED;

  return (
    !isActive &&
    (TenderStateChecks.isTerminated(supplierRequest.requestState) ||
      supplierRequest.archived ||
      isSupplierRejected(supplierRequest))
  );
}

/**
 * Validates if a supplier request should be in active offers
 */
function isActiveRequest(supplierRequest, tender, supplierId) {
  if (supplierRequest.supplierId !== supplierId) {
    return false;
  }

  return (
    supplierRequest.requestState === RequestState.ACCEPTED &&
    !isSupplierRejected(supplierRequest)
  );
}

function filterTendersBySupplierAndStates(tenders, supplierId, validationFn) {
  return tenders.filter((tender) =>
    tender.priceRequests.some((priceRequest) =>
      priceRequest.supplierRequests.some((supplierRequest) =>
        validationFn(supplierRequest, tender, supplierId),
      ),
    ),
  );
}

function getFilteredTenders(
  state,
  rootState,
  validationFn,
  transformFn = (t) => t,
) {
  const supplierId = rootState.supplier.supplier.id;
  const tenders = cloneDeep(getTendersFromStore(state));
  const filteredTenders = filterTendersBySupplierAndStates(
    tenders,
    supplierId,
    validationFn,
  );
  return mapToAddressTenders(transformFn(filteredTenders));
}

// Getters
export default {
  getAllTenders: (state) => state.offers,

  getRequestedOffers: (state, _getters, rootState) =>
    getFilteredTenders(state, rootState, isRequestedOffer),

  getOfferedOffers: (state, _getters, rootState) =>
    getFilteredTenders(
      state,
      rootState,
      (supplierRequest, tender, supplierId) =>
        supplierRequest.supplierId === supplierId &&
        RequestStateChecks.matchesStates(supplierRequest.requestState, [
          RequestState.OFFERED,
        ]) &&
        !TenderStateChecks.isTerminated(supplierRequest.requestState),
    ),

  getActiveOffers: (state, _getters, rootState) =>
    getFilteredTenders(state, rootState, isActiveRequest, createActiveTenders),

  getTerminatedOrArchivedOffers: (state, _getters, rootState) =>
    getFilteredTenders(state, rootState, isTerminatedRequest),
};
