import { Router } from "vue-router";
import { RouteNames } from "@/router/types";
import { useFileAttachmentApi } from "@/services/api/useFileAttachmentApi";
import { useTenderApi } from "@/services/api/useTenderApi";

const { fetchFileAttachment } = useFileAttachmentApi();
const { getTenderConfirmationUri } = useTenderApi();

const viewFileInNewWindow = async (
  file: (() => Promise<Blob | File>) | Blob | File,
  router: Router,
) => {
  const fileWindow = window.open(
    router.resolve({ name: RouteNames.FILE_VIEWER }).fullPath,
    "_blank",
  );
  if (!fileWindow) {
    return;
  }
  fileWindow.location.href = URL.createObjectURL(
    typeof file === "function" ? await file() : file,
  );
};

const viewBlobFile = async (
  blobFetchAPI: () => Promise<Blob>,
  router: Router,
) => {
  const pdfWindow = window.open(
    router.resolve({ name: RouteNames.FILE_VIEWER }).fullPath,
    "_blank",
  );
  if (!pdfWindow) {
    return;
  }
  pdfWindow.location.href = URL.createObjectURL(await blobFetchAPI());
};

const viewAttachmentFile = async (
  apiParams: {
    attachmentId: string;
    tenderId: number;
    supplierRequestId: number;
    supplierId: number;
  },
  router: Router,
) => {
  const blobFetchAPI = async () => {
    const response = await fetchFileAttachment({
      attachmentId: apiParams.attachmentId,
      tenderId: apiParams.tenderId,
      supplierRequestId: apiParams.supplierRequestId,
      supplierId: apiParams.supplierId,
    });
    return response.data;
  };
  await viewBlobFile(blobFetchAPI, router);
};

const viewOrderConfirmationDocument = async (
  tenderId: number,
  offerId: number,
  router: Router,
): Promise<void> => {
  const blobFetchAPI = async () => {
    const response = await getTenderConfirmationUri({
      tenderId: tenderId,
      offerId: offerId,
      audience: "Supplier",
    });
    return response.data;
  };
  await viewFileInNewWindow(blobFetchAPI, router);
};

type UseFileDownload = () => {
  viewBlobFile: typeof viewBlobFile;
  viewAttachmentFile: typeof viewAttachmentFile;
  viewFileInNewWindow: typeof viewFileInNewWindow;
  viewOrderConfirmationDocument: typeof viewOrderConfirmationDocument;
};

const useFileDownload: UseFileDownload = () => {
  return {
    viewBlobFile,
    viewAttachmentFile,
    viewFileInNewWindow,
    viewOrderConfirmationDocument,
  };
};

export type { UseFileDownload };

export default useFileDownload;
