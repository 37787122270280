<template>
  <div
    class="attachment"
    tabindex="0"
    role="button"
    :aria-label="`View attachment: ${attachment.fileName}`"
    @click="handleAttachmentClick"
    @keydown.enter="handleAttachmentClick"
  >
    <img
      :src="`/images/document_24.svg`"
      alt="Document icon"
      class="attachment__icon"
    />
    <div class="attachment__file-name">
      {{ attachment.fileName }}
    </div>
    <div v-if="isLoading" class="attachment__loading">
      <div class="attachment__spinner" />
    </div>
    <button
      v-else-if="removeButtonVisible"
      class="attachment__remove-button"
      type="button"
      :aria-label="`Remove attachment: ${attachment.fileName}`"
      @click="handleRemoveClick"
      @keydown.enter="handleRemoveClick"
    >
      <img
        :src="`/images/cross_20.svg`"
        alt="Remove icon"
        class="attachment__remove-button-icon"
      />
    </button>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import { useRouter } from "vue-router";
import { useFileAttachmentApi } from "@/services/api/useFileAttachmentApi";
import useFileDownload from "@/hooks/useFileDownload";

const props = defineProps<{
  attachment: {
    id: string;
    fileName: string;
    file?: File;
  };
  removable?: boolean;
  readOnly?: boolean;
  tender: {
    tenderId: number;
  };
  supplierId: number;
}>();

const emit = defineEmits<{
  (e: "remove"): void;
}>();

const router = useRouter();
const { getAttachment } = useFileAttachmentApi();
const { viewFileInNewWindow } = useFileDownload();
const isLoading = ref(false);

const removeButtonVisible = computed(() => !props.readOnly && props.removable);

const handleAttachmentClick = async () => {
  if (props.attachment.file) {
    window.open(URL.createObjectURL(props.attachment.file), "_blank");
    return;
  }

  if (props.attachment.id) {
    try {
      isLoading.value = true;
      const response = await getAttachment({
        attachmentId: props.attachment.id,
        tenderId: props.tender.tenderId,
        fileName: props.attachment.fileName,
        supplierId: props.supplierId,
      });
      await viewFileInNewWindow(response.data, router);
    } catch (error) {
      console.error("Error viewing attachment:", error);
    } finally {
      isLoading.value = false;
    }
  }
};

const handleRemoveClick = (event: Event) => {
  event.stopPropagation();
  emit("remove");
};
</script>

<style lang="scss" scoped>
.attachment {
  background-color: #fff;
  border: 1px solid $color-primary-pink-darker;
  border-radius: 4px;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  width: 14.375rem;
  cursor: pointer;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: rgba($color-primary-pink-darker, 0.05);
  }

  &:focus {
    outline: 2px solid $color-primary-pink-darker;
    outline-offset: 2px;
  }

  &__icon {
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 0.5rem;
    flex-shrink: 0;
  }

  &__file-name {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 0.875rem;
  }

  &__remove-button {
    background: none;
    border: none;
    padding: 0.25rem;
    margin-left: 0.5rem;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.2s ease;

    &:hover {
      background-color: rgba($color-primary-pink-darker, 0.1);
    }

    &:focus {
      outline: 2px solid $color-primary-pink-darker;
      outline-offset: 2px;
    }

    &-icon {
      width: 1.25rem;
      height: 1.25rem;
      display: block;
    }
  }

  &__loading {
    margin-left: 0.5rem;
    width: 1.25rem;
    height: 1.25rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__spinner {
    width: 1rem;
    height: 1rem;
    border: 2px solid rgba($color-primary-pink-darker, 0.2);
    border-top-color: $color-primary-pink-darker;
    border-radius: 50%;
    animation: spin 0.8s linear infinite;
  }
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
</style>
