<template>
  <div v-if="showComponent" class="work-description">
    <div class="work-description__title">
      {{ title }}
    </div>
    <div class="work-description__description">
      <custom-block-content :blocks="descriptionContent" />
    </div>
    <div v-if="localDescriptions.length" class="work-description__list">
      <div
        v-for="(descriptionItem, index) in localDescriptions"
        :key="descriptionItem.id"
        class="work-description__list-item"
      >
        <work-description-item
          :count="index + 1"
          :title="descriptionItem.title"
          :description="descriptionItem.description"
          :read-only="readOnly"
          @edit-item="openNewDescriptionModal(descriptionItem)"
          @delete-item="onDeleteWorkDescriptionItem(descriptionItem)"
        />
      </div>
    </div>
    <div v-else class="work-description__empty-state">
      <empty-state text="Ingen beskrivelse lagt til" />
    </div>
    <div v-if="!readOnly" class="work-description__new-point-button">
      <secondary-button
        :icon-src="newPointButtonIcon"
        :text="newPointButtonText"
        @click="openNewDescriptionModal"
      />
    </div>
    <delete-work-description-item-modal
      v-if="workDescriptionToDelete"
      v-model="showDeleteWorkDescriptionModal"
      :description-item="workDescriptionToDelete"
      @item-deleted="onWorkDescriptionItemDeleted"
    />
    <new-work-description-modal
      v-model="showNewWorkDescriptionModal"
      :work-description="workDescriptionToEdit"
      :form-cms="newDescriptionFormCms"
      @work-description-added="handleNewWorkDescription"
      @add-new-work-description="workDescriptionToEdit = null"
    />
  </div>
</template>

<script setup>
import { ref, computed, watch } from "vue";
import { cloneDeep, isEqual, sortBy } from "lodash";
import EmptyState from "@/components/EmptyState/EmptyState.vue";
import SecondaryButton from "@/components/Buttons/SecondaryButton.vue";
import WorkDescriptionItem from "@/components/SupplierOfferWorkDescription/WorkDescriptionItem.vue";
import DeleteWorkDescriptionItemModal from "@/components/SupplierOfferWorkDescription/DeleteWorkDescriptionItemModal.vue";
import NewWorkDescriptionModal from "@/components/NewWorkDescriptionModal/NewWorkDescriptionModal.vue";
import CustomBlockContent from "@/components/CustomBlockContent/CustomBlockContent.vue";
import cmsService from "@/services/cmsService";

const props = defineProps({
  pageCms: {
    type: Object,
    required: true,
  },
  offer: {
    type: Object,
    required: true,
  },
  readOnly: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["new-offer"]);

// State
const showNewWorkDescriptionModal = ref(false);
const showDeleteWorkDescriptionModal = ref(false);
const workDescriptionToDelete = ref(null);
const workDescriptionToEdit = ref(null);
const localDescriptions = ref([]);

// Computed
const showComponent = computed(
  () => !(props.readOnly && localDescriptions.value.length === 0),
);

const newDescriptionFormCms = computed(() =>
  props.pageCms.forms.find((section) => section.label === "newDescriptionForm"),
);

const workDescriptionCms = computed(() =>
  props.pageCms.pageSections.find(
    (section) => section.label === "workDescription",
  ),
);

const title = computed(() => workDescriptionCms.value.title);
const descriptionContent = computed(() => workDescriptionCms.value.content);

const newPointButton = computed(() =>
  workDescriptionCms.value.buttons.find(
    (button) => button.label === "newPointButton",
  ),
);

const newPointButtonText = computed(() => newPointButton.value.title);
const newPointButtonIcon = computed(() =>
  cmsService.resolveImage(newPointButton.value.icons[0].icon).url(),
);

// Methods
const normalizeDescription = (desc) => {
  const normalized = {
    title: desc.title,
    description: desc.description || desc.body,
  };

  if (desc.id && typeof desc.id === "number") {
    normalized.id = desc.id;
  }

  return normalized;
};

const checkIfDescriptionsAreEqual = (descriptionsA, descriptionsB) => {
  const normalizedA = descriptionsA?.map(normalizeDescription) || [];
  const normalizedB = descriptionsB?.map(normalizeDescription) || [];

  return isEqual(
    sortBy(normalizedA, ["id", "title"]),
    sortBy(normalizedB, ["id", "title"]),
  );
};

const handleNewWorkDescription = (data) => {
  const matchingDescription = localDescriptions.value.find(
    (workDescription) => workDescription.id === data.id,
  );

  if (matchingDescription) {
    Object.assign(matchingDescription, {
      title: data.title,
      description: data.description,
    });
  } else {
    localDescriptions.value.push({
      title: data.title,
      description: data.description,
    });
  }
};

const onWorkDescriptionItemDeleted = (descriptionItem) => {
  const index = localDescriptions.value.findIndex(
    (desc) => desc.id === descriptionItem.id,
  );
  if (index !== -1) {
    localDescriptions.value.splice(index, 1);
  }
};

const onDeleteWorkDescriptionItem = (descriptionItem) => {
  workDescriptionToDelete.value = descriptionItem;
  showDeleteWorkDescriptionModal.value = true;
};

const openNewDescriptionModal = (workDescription = null) => {
  workDescriptionToEdit.value = workDescription;
  showNewWorkDescriptionModal.value = true;
};

// Watchers
watch(
  () => props.offer.descriptions,
  () => {
    if (
      checkIfDescriptionsAreEqual(
        props.offer.descriptions,
        localDescriptions.value,
      )
    ) {
      return;
    }
    localDescriptions.value = cloneDeep(props.offer.descriptions);
  },
  { immediate: true, deep: true },
);

watch(
  () => props.offer,
  (newOffer) => {
    if (!newOffer) return;

    localDescriptions.value = cloneDeep(newOffer.descriptions || []);
  },
  { deep: true },
);

watch(
  localDescriptions,
  () => {
    if (
      checkIfDescriptionsAreEqual(
        props.offer.descriptions,
        localDescriptions.value,
      )
    ) {
      return;
    }
    const offerCopy = cloneDeep(props.offer);
    offerCopy.descriptions = cloneDeep(localDescriptions.value);
    emit("new-offer", offerCopy);
  },
  { deep: true },
);
</script>

<style lang="scss" scoped>
.work-description {
  &__title {
    font-weight: 600;
    font-size: $font-size-2xl;
  }

  &__description {
    margin: 0;
    font-weight: 400;
    font-size: $font-size-sm;
  }

  &__empty-state {
    margin-top: 1rem;
  }

  &__list {
    margin-top: 1rem;
  }

  &__list-item {
    &:not(:first-child) {
      margin-top: 1rem;
    }
  }

  &__new-point-button {
    margin-top: 1rem;
  }
}
</style>
