<template>
  <div class="terms">
    <h2>{{ title }}</h2>
    <div class="terms__description">
      <custom-block-content :blocks="description" />
    </div>

    <div class="terms__section">
      <service-start-date
        :page-cms="pageCms"
        :offer="offer"
        :read-only="readOnly"
        :minimum-days-to-start-date="minimumDaysToStartDate"
        @new-offer="handleNewOffer"
      />
    </div>

    <div v-if="hasRepeatingCosts" class="terms__section">
      <div class="notice-period-container">
        <div class="notice-period-header">
          <h3>{{ noticePeriodTitle }}</h3>
          <div class="notice-period-description">
            <custom-block-content :blocks="noticePeriodDescription" />
          </div>
        </div>

        <div class="notice-period-control">
          <button
            class="control-button"
            :disabled="readOnly"
            @click="decreaseNoticePeriod"
          >
            <img :src="Subtract" alt="Subtract" />
          </button>
          <div class="notice-period-value">
            <span class="number">{{ offer.noticePeriod || 0 }}</span>
            <span class="unit">måneder</span>
          </div>
          <button
            class="control-button"
            :disabled="readOnly"
            @click="increaseNoticePeriod"
          >
            <img :src="Add" alt="Add" />
          </button>
        </div>
      </div>
    </div>

    <div
      v-if="frameAgreement && frameAgreement.attachments.length"
      class="terms__section frame-agreement"
    >
      <div class="frame-agreement__icon">
        <img :src="Contract" alt="Contract" />
      </div>
      <div class="frame-agreement__content">
        <div class="frame-agreement__text">
          <h4>{{ frameAgreementTitle }}</h4>
          <div class="frame-agreement-description">
            <custom-block-content :blocks="frameAgreementDescription" />
          </div>
        </div>
        <div class="frame-agreement__attachments">
          <file-attachment
            v-for="attachment in frameAgreement.attachments"
            :key="attachment.attachmentId"
            :file-name="attachment.fileName"
            :loading="loadingAttachmentId === attachment.attachmentId"
            @download="downloadAttachment(attachment)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from "vue";
import Contract from "@/assets/img/contract.svg";
import Add from "@/assets/img/add.svg";
import Subtract from "@/assets/img/subtract.svg";
import ServiceStartDate from "@/views/TenderOffer/EditOfferView/ServiceStartDate.vue";
import { useRouter } from "vue-router";
import { useFileAttachmentApi } from "@/services/api/useFileAttachmentApi";
import useFileDownload from "@/hooks/useFileDownload";
import CustomBlockContent from "@/components/CustomBlockContent/CustomBlockContent.vue";
import FileAttachment from "./FileAttachment.vue";

const props = defineProps({
  pageCms: {
    type: Object,
    required: true,
  },
  tender: {
    type: Object,
    required: true,
  },
  supplierId: {
    type: Number,
    required: true,
  },
  offer: {
    type: Object,
    required: true,
  },
  readOnly: {
    type: Boolean,
    default: false,
  },
  minimumDaysToStartDate: {
    type: Number,
    default: 1,
  },
  frameAgreement: {
    type: Object,
    default: null,
  },
});

const emit = defineEmits(["new-offer"]);
const router = useRouter();
const { getAttachment } = useFileAttachmentApi();
const { viewFileInNewWindow } = useFileDownload();

const loadingAttachmentId = ref(null);

const termsCms = computed(() =>
  props.pageCms.pageSections.find((section) => section.label === "terms"),
);

const noticePeriodCms = computed(() =>
  props.pageCms.pageSections.find(
    (section) => section.label === "noticePeriod",
  ),
);

const frameAgreementCms = computed(() =>
  props.pageCms.pageSections.find(
    (section) => section.label === "frameAgreement",
  ),
);

const title = computed(() => termsCms.value?.title);
const description = computed(() => termsCms.value?.content);

const noticePeriodTitle = computed(() => noticePeriodCms.value?.title);
const noticePeriodDescription = computed(() => noticePeriodCms.value?.content);

const frameAgreementTitle = computed(() => frameAgreementCms.value?.title);
const frameAgreementDescription = computed(
  () => frameAgreementCms.value?.content,
);

const hasRepeatingCosts = computed(() => {
  return props.offer.fixedCostActivities.some(
    (activity) => activity.frequencyGroup.toLowerCase() !== "fixed",
  );
});

function handleNewOffer(updatedOffer) {
  emit("new-offer", updatedOffer);
}

function decreaseNoticePeriod() {
  if (props.readOnly) return;
  const currentPeriod = props.offer.noticePeriod || 0;
  if (currentPeriod > 1) {
    emit("new-offer", {
      ...props.offer,
      noticePeriod: currentPeriod - 1,
    });
  }
}

function increaseNoticePeriod() {
  if (props.readOnly) return;
  const currentPeriod = props.offer.noticePeriod || 0;
  emit("new-offer", {
    ...props.offer,
    noticePeriod: currentPeriod + 1,
  });
}

async function downloadAttachment(attachment) {
  loadingAttachmentId.value = attachment.attachmentId;
  try {
    const response = await getAttachment({
      attachmentId: attachment.attachmentId,
      tenderId: props.tender.tenderId,
      fileName: attachment.fileName,
      supplierId: props.supplierId,
    });
    await viewFileInNewWindow(response.data, router);
  } catch (error) {
    console.error("Error downloading attachment:", error);
  } finally {
    loadingAttachmentId.value = null;
  }
}
</script>

<style lang="scss" scoped>
.terms {
  font-family: "Montserrat", sans-serif;
  color: #1d1d1d;

  &__description {
    margin-top: 0.5rem;
  }

  .notice-period-description,
  .frame-agreement-description {
    :deep(p) {
      margin: 0;
      font-size: 14px;
      font-weight: 400;
      color: #5a5a5a;
    }
  }
}

.terms h2 {
  font-size: 24px;
  font-weight: 600;
}

.terms p {
  font-size: 14px;
  font-weight: 400;
  color: black;
}

.terms__section h3 {
  font-size: 16px;
  font-weight: 600;
}

.terms__section p {
  font-size: 14px;
  font-weight: 400;
  color: #5a5a5a;
}

.terms__section {
  margin-top: 24px;
}

.notice-period-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.notice-period-header {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.notice-period-header h3 {
  color: #1d1d1d;
  font-size: 16px;
  font-weight: 600;
  margin: 0;
}

.notice-period-header p {
  color: #5a5a5a;
  font-size: 14px;
  font-weight: 400;
  margin: 0;
}

.notice-period-control {
  width: 189px;
  height: 40px;
  padding: 4px;
  background: white;
  border-radius: 100px;
  border: 1px solid #2a2a2a;
  display: inline-flex;
  align-items: center;
}

.control-button {
  width: 32px;
  height: 32px;
  background: #441b49;
  border-radius: 100px;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  &:disabled {
    background: #cccccc;
    cursor: not-allowed;
  }
}

.minus-icon,
.plus-icon {
  width: 16px;
  height: 2px;
  background: white;
  position: relative;
}

.plus-icon::after {
  content: "";
  position: absolute;
  width: 2px;
  height: 16px;
  background: white;
  left: 7px;
  top: -7px;
}

.notice-period-value {
  flex: 1;
  padding: 0 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.number {
  color: #1d1d1d;
  font-size: 14px;
  font-weight: 400;
}

.unit {
  color: #1d1d1d;
  font-size: 12px;
  font-weight: 600;
}

.frame-agreement {
  display: inline-flex;
  gap: 8px;
  padding: 16px;
  background: #f1e6f5;
  border: 1px solid #dfcfe5;
  border-radius: 8px;
  width: 100%;
}

.frame-agreement__icon {
  width: 40px;
  height: 40px;
  position: relative;
}

.frame-agreement__content {
  flex: 1;
  display: inline-flex;
  flex-direction: column;
  gap: 8px;
}

.frame-agreement__text {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.frame-agreement__text h4 {
  font-size: 14px;
  font-weight: 600;
  margin: 0;
}

.frame-agreement__text p {
  font-size: 14px;
  font-weight: 400;
  margin: 0;
}

.frame-agreement__attachments {
  display: flow;
  flex-direction: row;
  gap: 16px;
}
</style>
